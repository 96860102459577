<template>
  <div>
    <router-view :key="$route.params.id" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import router from '@/router'

export default {
  name: 'employeeHome',
  mounted() {
    this.userCheck()
  },
  computed: {
    ...mapState(['userProfile', 'currentUser']),
  },
  methods: {
    // userCheck() {
    //   if (this.userProfile && this.userProfile.employmentType != 'EMPLOYEE') {
    //     this.$router.push('/account/details')
    //   }
    // },
  },
}
</script>